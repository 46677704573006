import { FC, ReactNode, createContext, useContext } from "react"

type Ambiente = "0" | "1"

const EnvContext = createContext<Ambiente>("0")
const useEnv = () => {
  const ambiente = useContext(EnvContext)
  return ambiente
}

type EnvProviderProps = {
  children: ReactNode
}

const EnvContextProvider: FC<EnvProviderProps> = ({ children }) => {
  const ambiente = (process.env.REACT_APP_AMBIENTE as Ambiente) || "0"

  return <EnvContext.Provider value={ambiente}>{children}</EnvContext.Provider>
}
export { EnvContext, EnvContextProvider, useEnv }
export type { Ambiente }
