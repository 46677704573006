import { Box } from '@mui/material'
import { styled } from '@mui/system'
import { FC } from 'react'

const LoadingContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  height: '100vh',
})

interface LoadingPageProps {
  urlLogo: string | undefined
}

const LoadingPage: FC<LoadingPageProps> = ({ urlLogo }) => {
  const url = urlLogo ?? 'https://s3.amazonaws.com/public.cesione.com.co/logo/LogoCesionbnk.png'
  return (
    <LoadingContainer>
      <Box component={'img'} width={'16vw'} height={'8vh'} className='loading-page' src={url} alt="Cargando" />
    </LoadingContainer>
  )
}

export { LoadingPage }
