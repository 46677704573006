import * as React from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem, TextField, Typography, useTheme, useMediaQuery } from '@mui/material';
import { NombreTipoDocumento } from '../../shared/dataLists';
import { PropsDatosUsuario } from '../../shared/VinculacionesModels';
const DatosUsuarioForm: React.FC<PropsDatosUsuario> = ({ datosPersonales, setDatosPersonales }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setDatosPersonales({
            ...datosPersonales,
            [name]: value
        });
    };
    return (
        <>
            <Typography variant='h6' sx={{ textAlign: 'left', mb: 3, mt: 3, fontWeight: '500' }}>
                Datos del Usuario
            </Typography>
            <Grid container spacing={isMobile ? 1 : 2}>
                <Grid item xs={12} md={6} sx={{ p: !isMobile ? 1 : 0, mt: 1 }}>
                    <TextField
                        fullWidth
                        label="Nombres"
                        variant="outlined"
                        value={datosPersonales.nombres}
                        onChange={handleChange}
                        name='nombres'
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{ p: !isMobile ? 1 : 0, mt: 1 }}>
                    <TextField
                        fullWidth
                        label="Apellidos"
                        variant="outlined"
                        value={datosPersonales.apellidos}
                        onChange={handleChange}
                        name='apellidos'
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{ p: !isMobile ? 1 : 0, mt: 1 }}>
                    <FormControl fullWidth sx={{ textAlign: 'left' }}>
                        <InputLabel id="identification-type-label">Tipo de identificación</InputLabel>
                        <Select
                            labelId="identification-type-label"
                            id="identification-type"
                            label="Tipo de Identificación"
                            value={datosPersonales.tipoIdentificacion}
                            onChange={handleChange}
                            name='tipoIdentificacion'
                        >
                            {Object.entries(NombreTipoDocumento).map(([key, value]) => (
                                <MenuItem key={key} value={parseInt(key)}>{value}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} sx={{ p: !isMobile ? 1 : 0, mt: 1 }}>
                    <TextField
                        fullWidth
                        label="Número de identificación"
                        variant="outlined"
                        value={datosPersonales.identificacion}
                        onChange={handleChange}
                        name='identificacion'
                        type='number'
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{ p: !isMobile ? 1 : 0, mt: 1 }}>
                    <TextField
                        fullWidth
                        label="Celular"
                        variant="outlined"
                        value={datosPersonales.telefono}
                        onChange={handleChange}
                        name='telefono'
                        type='number'
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{ p: !isMobile ? 1 : 0, mt: 1 }}>
                    <TextField
                        fullWidth
                        label="Correo corporativo"
                        variant="outlined"
                        value={datosPersonales.correo}
                        onChange={handleChange}
                        name='correo'
                        type='email'
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default DatosUsuarioForm;
