import VinculacionCliente from "./vinculacionCliente"
import { useState } from "react"
import { InfoVinculacion } from "../../shared/VinculacionesModels"
import DetalleVinculacion from "./registroVinculacion"

/**
 * Vista que esta encargada de la redirection en función de la información del LocalStorage
 * @returns Componente
 */
const ResumenVinculacion = () => {
  const [newVinculacion, setNewVinculacion] = useState(false)

  // Recupera el objeto de la vinculacion
  const item = localStorage.getItem('infoVinculacion') || "null"
  const dataVinculaciones = JSON.parse(item)

  // Valida el objeto
  if (dataVinculaciones) {
    if (!newVinculacion && dataVinculaciones.enviada) {
      // Validar la fecha de creacion para saber si eliminar el objeto
      let fechaHoy = new Date()
      let fechaCreacion = new Date(dataVinculaciones?.fecha)
      let diffFecha = (fechaHoy.getTime() - fechaCreacion.getTime()) / 1000 / 60 / 60 / 24

      // si la vinculacion tiene mas de 30 dias de tramitada se descarta
      if (diffFecha > 30) {
        localStorage.removeItem('infoVinculacion');
        window.location.reload()
      }

      // carga el formulario con la informacion
      const data = dataVinculaciones.data
      return (
        <DetalleVinculacion data={data} setNewVinculacion={setNewVinculacion} newVinculacion={newVinculacion} />
      )
    } else if (!dataVinculaciones.enviada) {
      const data = dataVinculaciones as InfoVinculacion
      return (<VinculacionCliente props={data} />)
    }
    else {
      return (
        <VinculacionCliente props={null} />
      )
    }
  } else {
    return (
      <VinculacionCliente props={null} />
    )
  }
}

export default ResumenVinculacion