import { Box, Button, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FC } from "react";
import { DetalleProps } from "../../shared/VinculacionesModels";
import { NombreTipoDocumento } from "../../shared/dataLists";
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { GetUrlTenant, useTenant, useEnv } from "../../shared/Context";

const DetalleVinculacion: FC<DetalleProps> = ({ data, setNewVinculacion, newVinculacion }) => {
  const theme = useTheme()
  const ambiente = useEnv()
  const {tenantId} = useTenant()
  const url = GetUrlTenant(tenantId, ambiente)

  const identificacion = NombreTipoDocumento[data.datosEmpresa.tipoIdentificacion] || "Tipo invalido";

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isDesktop = useMediaQuery(theme.breakpoints.down('lg'));
  const isLargeDesktop = useMediaQuery(theme.breakpoints.down('xl'));

  const Vinculacion = () => {
    setNewVinculacion(!newVinculacion)
    localStorage.removeItem('infoVinculacion');
  }

  return (
    <Paper elevation={3} sx={{
      width: isMobile ? '95vw' : (isDesktop ? '75vw' : (isLargeDesktop ? '65vw' : '50vw')),
      m: isMobile ? 5 : (isDesktop ? 5 : (isLargeDesktop ? 2 : 5)),
      p: isMobile ? 5 : (isDesktop ? 5 : (isLargeDesktop ? 4 : 5)),
      display: 'flex',
      flexDirection: 'column',
      // background: '#403c3c'
    }} >
      <Box sx={{
        minHeight: '50vh',
        flexDirection: "column",
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}>
        <Box sx={{ minWidth: 300, maxWidth: 400 }}>
          <Box >
            <CheckCircleIcon sx={{ fontSize: 120, color: '#c8f404' }} />
          </Box>
          <Typography variant={!isMobile ? "h5" : "h6"} sx={{ marginBottom: '1rem' }}>
            Se ha registrado exitosamente su solicitud de vinculación.
          </Typography>
          <Typography>
            Su solicitud ha sido enviada para revisión y está pendiente de aceptación. Se enviará un correo al representante legal cuando se tenga una novedad sobre la solicitud.
          </Typography>
        </Box>
        <Box sx={{ height: '20%', margin: '2rem 0' }}>
          <Box>
            <Typography>
              La solicitud enviada está a nombre de
            </Typography>
            <Typography variant="h6">
              {data.datosEmpresa.razonSocial}
            </Typography>
            <Typography variant="h6">
              {identificacion}&nbsp;
              {data.datosEmpresa.identificacion} {data.datosEmpresa.tipoIdentificacion === 31 && <> - {data.datosEmpresa.dv}</>}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Button
            sx={{ m: '1rem' }}
            variant="contained"
            href={url}
          >
            Ir al portal
          </Button>
          <Typography>
            ó
          </Typography>
          <Button onClick={Vinculacion}
            variant="outlined"
            color="primary"
            sx={{ margin: '1rem' }}>
            Registrar una nueva vinculación
          </Button>
        </Box>
      </Box>
    </Paper>
  )
}

export default DetalleVinculacion