import { Box, Typography } from "@mui/joy"

export const Footer = () => {
  return (
    <Box
      component="footer"
      className="footer-Mui"
    >
      <Typography level="body-xs" fontWeight={'md'} textAlign="center">
        © CESIONBNK | {new Date().getFullYear()}
      </Typography>
    </Box>
  )
}