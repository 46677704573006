import { FC, useState } from 'react'
import { Box, Card, CardContent, Typography, Button, IconButton, List, ListItem, ListItemText } from '@mui/material'
import { ExpandMore, ExpandLess } from '@mui/icons-material'
import { CesioneResponse } from '../../Models/ServiceBase'

const ErrorWithDetails: FC<CesioneResponse> = ({ reason, reasonCode, messages }) => {
  const [showMessages, setShowMessages] = useState(false)

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
      <Card sx={{ maxWidth: 650, p: 5 }}>
        <CardContent sx={{ textAlign: 'center' }}>
          {/* Mostrar razón solo si está disponible */}
          {reason ? (
            <>
              <Typography variant="h4" fontWeight="bold" color="error" gutterBottom>
                {reason}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                Código de error: {reasonCode}
              </Typography>
            </>
          ) : (
            <Typography variant="h5" color="error">
              Error desconocido
            </Typography>
          )}

          {/* Mostrar botón para ver más detalles solo si hay mensajes */}
          {messages && (
            <>
              <IconButton
                color="primary"
                onClick={() => setShowMessages(!showMessages)}
                aria-expanded={showMessages}
                aria-label="Ver detalles del error"
              >
                {showMessages ? <ExpandLess /> : <ExpandMore />} {showMessages ? 'Ocultar' : 'Ver más'}
              </IconButton>
              {showMessages && (
                <List>
                  {Array.isArray(messages) ? (
                    messages.map((message) => (
                      <ListItem key={messages.indexOf(message)}>
                        <ListItemText primary={message} sx={{ wordBreak: 'break-word' }} />
                      </ListItem>
                    ))
                  ) : (
                    <ListItem>
                      <ListItemText primary={messages} sx={{ wordBreak: 'break-word' }} />
                    </ListItem>
                  )}
                </List>
              )}
            </>
          )}

          {/* Botón para regresar al inicio */}
          <Box mt={3}>
            <Button variant="contained" color="primary" href="/">
              Volver al Inicio
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

const ErrorPage: FC = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Typography variant="h4" fontWeight="bold" color="textPrimary" gutterBottom>
        Ha ocurrido un error, por favor vuelve a cargar la página.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        href="/"
        sx={{ my: 2, fontWeight: 300, fontSize: '16px', textTransform: 'none' }}
      >
        Volver al inicio
      </Button>
      <Typography variant="body2" fontWeight="bold" color="textSecondary" textAlign="center" sx={{ mt: 4 }}>
        Si el error persiste por favor comunícate con soporte@cesionbnk.com.
      </Typography>
    </Box>
  )
}

export { ErrorWithDetails, ErrorPage }
