import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';
export default function ErrorSendingInformation() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Box sx={{
            minHeight: '50vh',
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <Box sx={{ minWidth: 300 }}>
                <Box >
                    <ErrorIcon color="error" sx={{ fontSize: 120 }} />
                </Box>
                <Typography variant={!isMobile ? "h4" : "h5"}>
                    Ha ocurrido un error enviando la información.
                </Typography>
            </Box>
        </Box>
    );
}