import Swal from "sweetalert2";

const SaveDataVinculacion = (name: string, data: any) => {
  try {

    localStorage.setItem(name, JSON.stringify(data))
    Swal.fire({
      icon: "success",
      title: "Información guardada",
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      }
    })
  } catch (error) {
    console.error('Error al guardar la información:', error);
    alert('Ocurrió un error al intentar guardar la información.');
  }
}

export { SaveDataVinculacion }