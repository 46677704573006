import { Box, LinearProgress, Typography, useMediaQuery, useTheme } from "@mui/material";

export default function SendingInformation() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Box sx={{
            minHeight: '50vh',
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <Box sx={{ minWidth: !isMobile ? 300 : 200 }}>
                <Typography variant={!isMobile ? "h4" : "h5"}>
                    Creando vinculación
                </Typography>
                <Box mt={2}>
                    <LinearProgress />
                </Box>
            </Box>
        </Box>
    );
}