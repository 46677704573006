import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useEffect } from "react";
import { useEnv } from "../../shared/Context/Environment/EnvironmentContext";
import { GetUrlTenant, useTenant } from "../../shared/Context";

export default function InformationSent() {
  const theme = useTheme();
  const ambiente = useEnv()
  const {tenantId} = useTenant()
  const url = GetUrlTenant(tenantId, ambiente)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    const redirect = setInterval(() => {
      window.location.href = url
    }, 30000)
    return () => clearInterval(redirect)
  }, [])
  return (
    <Box sx={{
      minHeight: '50vh',
      display: "flex",
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <Box sx={{ minWidth: 300, maxWidth: 400 }}>
        <Box >
          <CheckCircleIcon sx={{ fontSize: 120, color: '#c8f404' }} />
        </Box>
        <Typography variant={!isMobile ? "h5" : "h6"}>
          Se ha creado correctamente su solicitud de vinculación.
        </Typography>
        <Typography variant={"body2"} mt={3}>
          Su solicitud ha sido enviada para revisión y está pendiente de aceptación. Se enviará un correo al representante legal cuando se tenga una novedad sobre la solicitud.
        </Typography>
        <Button
          sx={{ m: '1rem' }}
          variant="contained"
          href={url}
        >
          Ir al portal
        </Button>
      </Box>
    </Box>
  );
}