import { Ambiente } from "../Environment/EnvironmentContext"

const TenantCfinancia = '265a0493-740a-11ee-a243-12a5defed043'

const GetUrlTenant = (tenantId: string, ambiente: Ambiente) => {
    let suffix = ambiente == "0" ? "-test" : ""
    if (tenantId == TenantCfinancia) {
        return "https://cfinancia" + suffix + ".cesionbnk.com/"
    } else
        return "https://portal" + suffix + ".cesionbnk.com/"
}

export { TenantCfinancia, GetUrlTenant }
