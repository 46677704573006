import { Box } from "@mui/joy"
import { useEnv, useTenant } from "../../Context"
import { AppBar, Chip, Toolbar } from "@mui/material"
import { BugReport } from "@mui/icons-material"

export function Header() {
  const { logoBlanco } = useTenant()
  const ambiente = useEnv()
  const isProduction = ambiente === "1"
  const ambienteText = isProduction ? "" : "Ambiente de Pruebas"
  const ambienteColor = isProduction ? "default" : "warning"
  return (
    <Box
      height={"9vh"}
      sx={{
        display: "flex",
        justifyContent: "center",
        flexGrow: 1,
      }}
    >
      <AppBar
        position="static"
        sx={{ justifyContent: "center", background: "#281c2c" }}
      >
        <Toolbar>
          <Box
            component={"img"}
            src={logoBlanco}
            className="header-logo"
            width={"13vw"}
            height={"6vh"}
          />
          <Chip
            label={ambienteText}
            color={ambienteColor}
            icon={isProduction ? <></> : <BugReport />}
            sx={{ fontWeight: "600", marginLeft: 2 }}
            variant="filled"
          />
        </Toolbar>
      </AppBar>
    </Box>
  )
}
