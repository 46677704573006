import * as React from 'react';
import { Grid, Box, FormControl, InputLabel, Select, MenuItem, TextField, Typography, FormControlLabel, Checkbox, useTheme, useMediaQuery } from '@mui/material';
import { NombreTipoDocumento } from '../../shared/dataLists';
import { useEffect } from 'react';
import { PropsDatosRepLegal } from '../../shared/VinculacionesModels';

const DatosRepresentanteLegalForm: React.FC<PropsDatosRepLegal> = ({
    datosPersonales,
    datosRepresentanteLegal,
    setDatosRepresentanteLegal }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setDatosRepresentanteLegal({
            ...datosRepresentanteLegal,
            [name]: value
        });
    };
    const [isRepLegal, setIsRepLegal] = React.useState(datosRepresentanteLegal.repLegal);
    const handleClickIsRepLegal = () => {
        setIsRepLegal(!isRepLegal);
    };


    const getReplaceValues = () => {
        if (isRepLegal) {
            setDatosRepresentanteLegal((prevState: any) => ({
                ...prevState,
                nombres: datosPersonales.nombres,
                apellidos: datosPersonales.apellidos,
                tipoIdentificacion: datosPersonales.tipoIdentificacion,
                identificacion: datosPersonales.identificacion,
                telefono: datosPersonales.telefono,
                correo: datosPersonales.correo,
                repLegal: true
            }));
        } else {
            setDatosRepresentanteLegal((prevState: any) => ({
                ...prevState,
                repLegal: false
            }));
        }
    }
    useEffect(() => {
        getReplaceValues();
    }, [isRepLegal, getReplaceValues]);


    return (
        <>
            <Typography variant='h6' sx={{ textAlign: 'left', mb: 3, mt: 3, fontWeight: '500' }}>
                Datos representante legal
            </Typography>
            <Grid container spacing={isMobile ? 1 : 2}>
                <Grid item md={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'justify', mt: isMobile ? 1 : 0 }}>
                        <FormControlLabel control={
                            <Checkbox
                                onChange={handleClickIsRepLegal}
                                value={datosRepresentanteLegal.repLegal}
                                checked={isRepLegal}
                                name='repLegal' />}
                            label="Yo soy el representante legal" />
                    </Box>
                </Grid>

                <Grid item xs={12} md={6} sx={{ p: !isMobile ? 1 : 0, mt: 1 }}>
                    <TextField
                        fullWidth
                        label="Nombres"
                        variant="outlined"
                        value={datosRepresentanteLegal.nombres}
                        onChange={handleChange}
                        name='nombres'
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{ p: !isMobile ? 1 : 0, mt: 1 }}>
                    <TextField
                        fullWidth
                        label="Apellidos"
                        variant="outlined"
                        value={datosRepresentanteLegal.apellidos}
                        onChange={handleChange}
                        name='apellidos'
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{ p: !isMobile ? 1 : 0, mt: 1 }}>
                    <FormControl fullWidth sx={{ textAlign: 'left' }}>
                        <InputLabel id="identification-type-label">Tipo de identificación</InputLabel>
                        <Select
                            labelId="identification-type-label"
                            id="identification-type"
                            label="Tipo de Identificación"
                            value={datosRepresentanteLegal.tipoIdentificacion}
                            onChange={handleChange}
                            name='tipoIdentificacion'
                        >
                            {Object.entries(NombreTipoDocumento).map(([key, value]) => (
                                <MenuItem key={key} value={parseInt(key)}>{value}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} sx={{ p: !isMobile ? 1 : 0, mt: 1 }}>
                    <TextField
                        fullWidth
                        label="Número de identificación"
                        variant="outlined"
                        value={datosRepresentanteLegal.identificacion}
                        onChange={handleChange}
                        name='identificacion'
                        type='number'
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{ p: !isMobile ? 1 : 0, mt: 1 }}>
                    <TextField
                        fullWidth
                        label="Celular"
                        variant="outlined"
                        value={datosRepresentanteLegal.telefono}
                        onChange={handleChange}
                        name='telefono'
                        type='number'
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{ p: !isMobile ? 1 : 0, mt: 1 }}>
                    <TextField
                        fullWidth
                        label="Correo corporativo"
                        variant="outlined"
                        value={datosRepresentanteLegal.correo}
                        onChange={handleChange}
                        name='correo'
                        type='email'
                    />
                </Grid>

                <Grid item xs={12} sx={{}}>
                    <Box sx={{ textAlign: !isMobile ? 'left' : 'justify', bgcolor: 'primary.main', '&:hover': { bgcolor: 'primary.dark', }, borderRadius: 1, padding: '1rem', color: 'white' }}>
                        Recuerde que la información ingresada durante el proceso de vinculación será objeto de revisión y validación. Una vez concluida esta etapa, se enviará un correo al representante legal con el procedimiento a seguir.
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default DatosRepresentanteLegalForm;
