import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react"
import { TenantInfo } from "../../Models/TenantInfo"
import { CesioneResponse } from "../../Models/ServiceBase"
import { ErrorPage, ErrorWithDetails, LoadingPage } from "../../Layout"

interface TenantProviderProps {
  children: ReactNode
}

interface GetTenantPropsResponse extends CesioneResponse {
  tenantInfo: TenantInfo
}

let defaultTenant: TenantInfo = {
  tenantId: "f8df8273-3deb-11ed-b378-0e83af69634d",
  name: "CESIONBNK | CLIENTES",
  favicon:
    "https://s3.amazonaws.com/public.cesione.com.co/favicon/FaviconCesion.ico",
  logoBlanco:
    "https://s3.amazonaws.com/public.cesione.com.co/logo/LogoCesionbnkBlanco.png",
  logoNegro:
    "https://s3.amazonaws.com/public.cesione.com.co/logo/LogoCesionbnk.png",
}

const TenantContext = createContext<TenantInfo>(defaultTenant)

const useTenant = (): TenantInfo => {
  const tenantInfo = useContext(TenantContext)
  return tenantInfo
}

const TenantProvider: FC<TenantProviderProps> = ({ children }) => {
  const [tenantInfoResponse, setTenantInfoResponse] =
    useState<GetTenantPropsResponse>({
      reasonCode: 2,
      reason: "Operación no ejecutada",
      succeeded: false,
      tenantInfo: defaultTenant,
    })
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<boolean>(false)

  // Obtiene el nombre del host
  const location = window.location.hostname.replace("local.", "")

  useEffect(() => {
    // Hace la peticion
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ host: location }),
    }
    const fetchTenantProps = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_CORE_LAMBDA_URL + "sistema/getTenantProps",
          requestOptions
        )
        const result = await response.json()
        if (!response.ok) {
          setError(true)
        } else {
          if (!result.succeeded) {
            setError(true)
          } else {
            setTenantInfoResponse(result)
            document.title = result.tenantInfo.name
            const link = document.querySelector(
              "link[rel*='icon']"
            ) as HTMLLinkElement
            if (link) {
              link.href = result.tenantInfo.favicon
            }
          }
        }
      } catch (err) {
        console.error(err)
        setError(true)
      } finally {
        setLoading(false)
      }
    }

    fetchTenantProps()
  }, [])

  if (loading) {
    return <LoadingPage urlLogo={tenantInfoResponse?.tenantInfo?.logoNegro} />
  }

  if (error) {
    return <ErrorPage />
  } else if (!tenantInfoResponse?.succeeded) {
    return <ErrorWithDetails {...tenantInfoResponse} />
  }
  return (
    <TenantContext.Provider value={tenantInfoResponse.tenantInfo}>
      {children}
    </TenantContext.Provider>
  )
}

export { TenantProvider, useTenant }
