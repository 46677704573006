import { Autocomplete, Box, Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DatosPagador, PropsDatosPagadores } from '../../shared/VinculacionesModels';
import companies from '../../shared/companies.json';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { useState } from 'react';

const PagadoresForm: React.FC<PropsDatosPagadores> = ({ datosPagadores, setDatosPagadores }) => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const handleDelete = (index: number) => {
        setDatosPagadores(datosPagadores.filter((_, rowIndex) => rowIndex !== index));
    };
    const saveRow = (index: number) => {
        const updatedRows = [...datosPagadores]
        updatedRows[index].editable = false
        setDatosPagadores(updatedRows)
    };
    const handleChangeAutocomplete = (event: any, value: DatosPagador[]) => {
        if (value != null && value.length != 0) {
            setDatosPagadores([...datosPagadores, { nit: value[0].nit, empresa: value[0].empresa, editable: false }])
        }  
    };
    const addRow = () => {
        setDatosPagadores([...datosPagadores, { nit: '', empresa: '', editable: true }]);
        setOpen(false);
    };
    const handleChangeTextFild = <T extends keyof DatosPagador>(index: number, field: T, value: any) => {
        const updatedRows = [...datosPagadores]
        updatedRows[index][field] = value
        setDatosPagadores(updatedRows)
    };
    const filteredOptions = companies.filter((option) => {
        return option.nit.toLowerCase() || option.empresa.toLowerCase();
    });
 
    return (
        <>
            <Typography variant='h6' sx={{ textAlign: 'left', mb: 3, mt: 3, fontWeight: '500' }}>
                Busca a tus clientes. Agrega todos los que quieras:
            </Typography>
            <Grid container spacing={isMobile ? 1 : 2}>
                <Grid item xs={12} md={12} sx={{ p: !isMobile ? 1 : 0, mt: 1 }}>
                    <Autocomplete
                        multiple
                        options={filteredOptions}
                        renderTags={(tags, getTagProps) => null}
                        getOptionLabel={(option) => `${option.nit} - ${option.empresa}`}
                        value={[]}
                        onChange={handleChangeAutocomplete}
                        clearIcon={null}
                        noOptionsText={
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                <p style={{ margin: 0 }}>No se encontró la empresa en el listado</p>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={addRow}
                                >
                                    Agregar cliente
                                </Button>
                            </div>
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Buscar"
                            />
                        )}
                        open={open}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                    />
                </Grid>
                {datosPagadores.length != 0 &&
                    <Grid item xs={12} md={12} sx={{ p: !isMobile ? 1 : 0, mt: 1 }}>
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>RAZÓN SOCIAL</TableCell>
                                        <TableCell>NIT</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {datosPagadores.map((row, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            {row.editable ? (
                                                <>
                                                    <TableCell component="th" scope="row">
                                                        <TextField
                                                            value={row.empresa}
                                                            onChange={(e) => handleChangeTextFild(index, 'empresa', e.target.value.toUpperCase())}
                                                            variant="outlined"
                                                            size="small"
                                                            style={{ width: '100%', marginRight: '5rem' }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            value={row.nit}
                                                            onChange={(e) => handleChangeTextFild(index, 'nit', e.target.value)}
                                                            variant="outlined"
                                                            size="small"
                                                            style={{ width: '100%' }}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="right" style={{ display: "flex" }}>
                                                        <Box>
                                                            <Tooltip title="Eliminar">
                                                                <IconButton
                                                                    size='small'
                                                                    sx={{ color: '#a90000' }}
                                                                    onClick={() => handleDelete(index)}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Box>
                                                        <Box>
                                                            <Tooltip title="Guardar">
                                                                <IconButton
                                                                    size='small'
                                                                    sx={{ color: '#155724' }}
                                                                    onClick={() => saveRow(index)}
                                                                >
                                                                    <PersonAddAlt1Icon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Box>
                                                    </TableCell>
                                                </>
                                            ) : (
                                                <>
                                                    <TableCell component="th" scope="row">
                                                        {row.empresa}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.nit}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                                                            <Tooltip title="Eliminar">
                                                                <IconButton
                                                                    size='small'
                                                                    sx={{ color: '#a90000' }}
                                                                    onClick={() => handleDelete(index)}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Box>
                                                    </TableCell>
                                                </>
                                            )}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                }
            </Grid>
        </>
    )
}

export default PagadoresForm