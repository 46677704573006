import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import DatosBancariosForm from './datosBancariosForm';
import { Autocomplete, Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, useMediaQuery } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { ListadoBancos } from '../../shared/dataLists';
import { PropsDatosBancarios } from '../../shared/VinculacionesModels';

const DatosBancariosFormRender: React.FC<PropsDatosBancarios> = ({ datosBancarios, datosBancarios2, datosBancarios3, setDatosBancarios, setDatosBancarios2, setDatosBancarios3, idVinculacion, datosEmpresa, datosBancariosRenderizados, setDatosBancariosRenderizados }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [cantidadCuentasAgregadas, setCantidadCuentasAgregadas] = useState(datosBancariosRenderizados);
    const handleAgregarCuenta = () => {
        if (cantidadCuentasAgregadas === 0) {
            setCantidadCuentasAgregadas(cantidadCuentasAgregadas + 2);
        } else {
            setCantidadCuentasAgregadas(cantidadCuentasAgregadas + 1);
        }
    };

    const FormularioRender = ({ numeroFormulario }: { numeroFormulario: number }) => {
        return (
            <DatosBancariosForm
                datosBancarios={datosBancarios}
                datosBancarios2={datosBancarios2}
                datosBancarios3={datosBancarios3}
                setDatosBancarios={setDatosBancarios}
                setDatosBancarios2={setDatosBancarios2}
                setDatosBancarios3={setDatosBancarios3}
                idVinculacion={idVinculacion}
                numeroFormulario={numeroFormulario} />
        );
    }

    const [cuentaAEliminar, setCuentaAEliminar] = useState<number | null>(null);
    const handleEliminarCuenta = (indice: number) => {
        setCuentaAEliminar(indice);
    };
    const eliminarCuenta = () => {
        if (cuentaAEliminar !== null) {
            switch (cuentaAEliminar) {
                case 1:
                    setDatosBancarios(datosBancarios2);
                    setDatosBancarios2(datosBancarios3);
                    setDatosBancarios3({
                        banco: 0,
                        tipoCuenta: 2,
                        cuenta: '',
                        certificacionBancaria: ''
                    });
                    break;
                case 2:
                    setDatosBancarios2(datosBancarios3);
                    setDatosBancarios3({
                        banco: 0,
                        tipoCuenta: 2,
                        cuenta: '',
                        certificacionBancaria: ''
                    });
                    break;
                case 3:
                    setDatosBancarios3({
                        banco: 0,
                        tipoCuenta: 2,
                        cuenta: '',
                        certificacionBancaria: ''
                    });
                    break;
                default:
                    break;
            }
            if (cantidadCuentasAgregadas === 2) {
                setCantidadCuentasAgregadas(cantidadCuentasAgregadas - 2);
            } else {
                setCantidadCuentasAgregadas(cantidadCuentasAgregadas - 1);
            }
            setCuentaAEliminar(null);
        }
    };
    React.useEffect(() => {
        setDatosBancariosRenderizados(cantidadCuentasAgregadas);
    }, [cantidadCuentasAgregadas]);

    React.useEffect(() => {
        eliminarCuenta();
    }, [cuentaAEliminar]);
    return (
        <>
            <Typography variant='h6' sx={{ textAlign: 'left', mb: 3, mt: 3, fontWeight: '500' }}>
                Datos Bancarios
            </Typography>
            <Typography sx={{ textAlign: 'left', mb: 3, mt: 3, fontWeight: '500' }}>
                Los datos de la cuenta a inscribir deben coincidir con los datos empresariales.
            </Typography>
            <Grid container spacing={isMobile ? 1 : 2}>
                <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                    <TextField
                        fullWidth
                        label="Razón social"
                        variant="outlined"
                        value={datosEmpresa.razonSocial}
                        name='nombreTitular'
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                    <TextField
                        fullWidth
                        label="Número de identificación"
                        variant="outlined"
                        value={datosEmpresa.identificacion}
                        disabled
                    />
                </Grid>
            </Grid >
            {cantidadCuentasAgregadas === 0 && (
                <>
                    <Typography variant='h6' sx={{ textAlign: 'left', mb: 3, mt: 3, fontWeight: '500' }}>
                        Datos de cuenta
                    </Typography>
                    <Grid container spacing={isMobile ? 1 : 2}>
                        <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    id="banco1"
                                    options={ListadoBancos}
                                    getOptionLabel={(option) => option.label}
                                    value={ListadoBancos.find((option) => option.value === datosBancarios.banco) || null}
                                    renderInput={(params) => <TextField {...params} label="Banco" />}
                                    onChange={(event, newValue) => {
                                        setDatosBancarios({ ...datosBancarios, banco: newValue ? newValue.value : 0 });
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                            <FormControl fullWidth sx={{ textAlign: 'left' }}>
                                <InputLabel id="tipo-cuenta-label">Tipo de cuenta</InputLabel>
                                <Select
                                    labelId="tipo-cuenta-label1"
                                    id="tipo-cuenta1"
                                    value={datosBancarios.tipoCuenta}
                                    onChange={(e) => setDatosBancarios({ ...datosBancarios, tipoCuenta: e.target.value as number })}
                                    label="Tipo de cuenta"
                                >
                                    <MenuItem value={2} >Ahorros</MenuItem>
                                    <MenuItem value={1}>Corriente</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                            <TextField
                                fullWidth
                                label="Número de cuenta"
                                variant="outlined"
                                value={datosBancarios.cuenta}
                                type='number'
                                helperText="Sin guiones ni puntos"
                                onChange={(e) => setDatosBancarios({ ...datosBancarios, cuenta: e.target.value })}
                                name='cuenta1'
                            />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                            <FormularioRender numeroFormulario={1} />
                        </Grid>
                    </Grid>
                </>
            )}

            {cantidadCuentasAgregadas >= 1 && (
                <Box sx={{ boxShadow: 3, p: isMobile ? 2 : 2, mb: 3, mt: 3, borderRadius: 3 }}>
                    <Grid container>
                        <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'left', textAlign: 'left', alignItems: 'center' }}>
                                {(datosBancarios.cuenta === '') && (
                                    <Typography>Nueva cuenta</Typography>
                                )}
                                {(datosBancarios.cuenta !== '') && (
                                    <Typography>Cuenta: {datosBancarios.cuenta}</Typography>
                                )}
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                                <IconButton size='small' aria-label="delete" sx={{ color: '#a90000' }}
                                    onClick={() => handleEliminarCuenta(1)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={isMobile ? 1 : 2}>
                        <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    id="banco"
                                    options={ListadoBancos}
                                    getOptionLabel={(option) => option.label}
                                    value={ListadoBancos.find((option) => option.value === datosBancarios.banco) || null}
                                    renderInput={(params) => <TextField {...params} label="Banco" />}
                                    onChange={(event, newValue) => {
                                        setDatosBancarios({ ...datosBancarios, banco: newValue ? newValue.value : 0 });
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                            <FormControl fullWidth sx={{ textAlign: 'left' }}>
                                <InputLabel id="tipo-cuenta-label">Tipo de cuenta</InputLabel>
                                <Select
                                    labelId="tipo-cuenta-label"
                                    id="tipo-cuenta"
                                    value={datosBancarios.tipoCuenta}
                                    onChange={(e) => setDatosBancarios({ ...datosBancarios, tipoCuenta: e.target.value as number })}
                                    label="Tipo de cuenta"
                                >
                                    <MenuItem value={2}>Ahorros</MenuItem>
                                    <MenuItem value={1}>Corriente</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                            <TextField
                                fullWidth
                                label="Número de cuenta"
                                variant="outlined"
                                value={datosBancarios.cuenta}
                                type='number'
                                helperText="Sin guiones ni puntos"
                                onChange={(e) => setDatosBancarios({ ...datosBancarios, cuenta: e.target.value })}
                                name='cuenta'
                            />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                            <FormularioRender numeroFormulario={1} />
                        </Grid>
                    </Grid>
                </Box>
            )}
            {(cantidadCuentasAgregadas === 2 || cantidadCuentasAgregadas === 3) && (
                <Box sx={{ boxShadow: 3, p: isMobile ? 2 : 2, mb: 3, mt: 3, borderRadius: 3 }}>
                    <Grid container>
                        <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'left', textAlign: 'left', alignItems: 'center' }}>
                                {(datosBancarios2.cuenta === '') && (
                                    <Typography>Nueva cuenta</Typography>
                                )}
                                {(datosBancarios2.cuenta !== '') && (
                                    <Typography>Cuenta: {datosBancarios2.cuenta}</Typography>
                                )}
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                                <IconButton size='small' aria-label="delete" sx={{ color: '#a90000' }}
                                    onClick={() => handleEliminarCuenta(2)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={isMobile ? 1 : 2}>
                        <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    id="banco2"
                                    options={ListadoBancos}
                                    getOptionLabel={(option) => option.label}
                                    value={ListadoBancos.find((option) => option.value === datosBancarios2.banco) || null}
                                    renderInput={(params) => <TextField {...params} label="Banco" />}
                                    onChange={(event, newValue) => {
                                        setDatosBancarios2({ ...datosBancarios2, banco: newValue ? newValue.value : 0 });
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                            <FormControl fullWidth sx={{ textAlign: 'left' }}>
                                <InputLabel id="tipo-cuenta-label">Tipo de cuenta</InputLabel>
                                <Select
                                    labelId="tipo-cuenta-label"
                                    id="tipo-cuenta"
                                    value={datosBancarios2.tipoCuenta}
                                    onChange={(e) => setDatosBancarios2({ ...datosBancarios2, tipoCuenta: e.target.value as number })}
                                    label="Tipo de cuenta"
                                >
                                    <MenuItem value={2}>Ahorros</MenuItem>
                                    <MenuItem value={1}>Corriente</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                            <TextField
                                fullWidth
                                label="Número de cuenta"
                                variant="outlined"
                                value={datosBancarios2.cuenta}
                                type='number'
                                helperText="Sin guiones ni puntos"
                                onChange={(e) => setDatosBancarios2({ ...datosBancarios2, cuenta: e.target.value })}
                                name='cuenta2'
                            />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                            <FormularioRender numeroFormulario={2} />
                        </Grid>
                    </Grid>
                </Box>
            )}
            {(cantidadCuentasAgregadas === 3) && (
                <Box sx={{ boxShadow: 3, p: isMobile ? 2 : 2, mb: 3, mt: 3, borderRadius: 3 }}>
                    <Grid container>
                        <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'left', textAlign: 'left', alignItems: 'center' }}>
                                {(datosBancarios3.cuenta === '') && (
                                    <Typography>Nueva cuenta</Typography>
                                )}
                                {(datosBancarios3.cuenta !== '') && (
                                    <Typography>Cuenta: {datosBancarios3.cuenta}</Typography>
                                )}
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                                <IconButton size='small' aria-label="delete" sx={{ color: '#a90000' }}
                                    onClick={() => handleEliminarCuenta(3)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={isMobile ? 1 : 2}>
                        <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    id="banco3"
                                    options={ListadoBancos}
                                    getOptionLabel={(option) => option.label}
                                    value={ListadoBancos.find((option) => option.value === datosBancarios3.banco) || null}
                                    renderInput={(params) => <TextField {...params} label="Banco" />}
                                    onChange={(event, newValue) => {
                                        setDatosBancarios3({ ...datosBancarios3, banco: newValue ? newValue.value : 0 });
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                            <FormControl fullWidth sx={{ textAlign: 'left' }}>
                                <InputLabel id="tipo-cuenta-label">Tipo de cuenta</InputLabel>
                                <Select
                                    labelId="tipo-cuenta-label"
                                    id="tipo-cuenta"
                                    value={datosBancarios3.tipoCuenta}
                                    onChange={(e) => setDatosBancarios3({ ...datosBancarios3, tipoCuenta: e.target.value as number })}
                                    label="Tipo de cuenta"
                                >
                                    <MenuItem value={2}>Ahorros</MenuItem>
                                    <MenuItem value={1}>Corriente</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                            <TextField
                                fullWidth
                                label="Número de cuenta"
                                variant="outlined"
                                value={datosBancarios3.cuenta}
                                type='number'
                                helperText="Sin guiones ni puntos"
                                onChange={(e) => setDatosBancarios3({ ...datosBancarios3, cuenta: e.target.value })}
                                name='cuenta3'
                            />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                            <FormularioRender numeroFormulario={3} />
                        </Grid>
                    </Grid>
                </Box>
            )}
            {(cantidadCuentasAgregadas < 3) && (
                <>
                    <Box mt={3}>
                        <Button
                            variant="text"
                            startIcon={<AddIcon />}
                            onClick={handleAgregarCuenta}
                        >
                            Agregar cuenta
                        </Button>
                    </Box>
                </>
            )}
        </>
    );
}
export default DatosBancariosFormRender;
