import * as React from 'react';
import { Grid, Typography, useMediaQuery, useTheme, Autocomplete, Box, TextField } from '@mui/material';
import { PropsDatosEmpresa } from '../../shared/VinculacionesModels';
import { DatePicker } from '@mui/x-date-pickers';
import actividadesEconomicas from '../../shared/actividadesEconomicas.json'
import dayjs, { Dayjs } from 'dayjs';
import { SyntheticEvent, useEffect, useState } from 'react';
import 'dayjs/locale/es';
import axios from 'axios';
import { styled, lighten, darken } from '@mui/system';

// Configura dayjs para usar español
dayjs.locale('es');

// Tipo de dato para los resultados de la API
interface Ciudad {
    departamento: string;
    municipio: string;
}

const DatosEmpresaForm: React.FC<PropsDatosEmpresa> = ({ datosEmpresa, setDatosEmpresa }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [datos, setDatos] = useState<Ciudad[]>([]);
    const GroupHeader = styled('div')(({ theme }) => ({
        position: 'sticky',
        top: '-8px',
        padding: '4px 10px',
        color: theme.palette.primary.main,
        backgroundColor: lighten(theme.palette.primary.light, 0.85),
        ...theme.applyStyles('dark', {
          backgroundColor: darken(theme.palette.primary.main, 0.8),
        }),
      }));
    const GroupItems = styled('ul')({
    padding: 0,
    });

    // Cargar los datos de la API en el montaje del componente
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://public.cesionbnk.com/static/data-sets/municipios.json');
                const datosFiltrados: Ciudad[] = response.data.map((item: any) => ({
                    departamento: item.departamento,
                    municipio: item.municipio
                }));
                setDatos(datosFiltrados);
            } catch (error) {
                console.error('Error al obtener los datos:', error);
            }
        };
        fetchData();
    }, []);

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setDatosEmpresa({
            ...datosEmpresa,
            [name]: value
        });
    };
    
    const handleDateChange = (newValue: Dayjs | null) => {
        if (newValue) {
            const formattedDate = newValue.startOf('day');
            setDatosEmpresa({
                ...datosEmpresa,
                fechaConstitucion: formattedDate
            });
        }
    };
    const handleChangeActivities = (event: SyntheticEvent<Element, Event>, newValue: { id: string; label: string } | null) => {
        setDatosEmpresa({
            ...datosEmpresa,
            codigoCiiu: newValue?.id,
            actividadEconomica: newValue?.label
        });
    };
    const handleChangeLocation = (event: SyntheticEvent<Element, Event>, newValue: Ciudad | null) => {
        setDatosEmpresa({
            ...datosEmpresa,
            municipio: newValue?.municipio,
            departamento: newValue?.departamento
        });
    };
    const filteredOptions = actividadesEconomicas.filter((option) => {
        return option.id.toLowerCase() || option.label.toLowerCase();
    });

    return (
        <>
            <Typography variant='h6' sx={{ textAlign: 'left', mb: 3, mt: 3, fontWeight: '500' }}>
                Datos de la Empresa
            </Typography>
            <Grid container spacing={isMobile ? 1 : 2}>
                <Grid item xs={12} md={12} sx={{ p: !isMobile ? 1 : 0, mt: 1 }}>
                    <TextField
                        fullWidth
                        label="Razón social"
                        variant="outlined"
                        value={datosEmpresa.razonSocial}
                        onChange={handleChange}
                        name="razonSocial"
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{ p: !isMobile ? 1 : 0, mt: 1 }}>
                    <DatePicker
                        sx={{ minWidth: "100%" }}
                        label="Fecha de constitución"
                        value={dayjs(datosEmpresa.fechaConstitucion)}
                        onChange={handleDateChange}
                        maxDate={dayjs()}
                        format='DD/MM/YYYY'
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{ p: !isMobile ? 1 : 0, mt: 1 }}>
                    <Autocomplete
                        options={datos.sort((a, b) => -b.departamento.localeCompare(a.departamento))}
                        groupBy={(option) => option.departamento}
                        getOptionLabel={(option) => option.municipio}
                        value={datos.filter(x => x.municipio === datosEmpresa.municipio).length > 0 ? datos.filter(x => x.municipio === datosEmpresa.municipio)[0] : null}
                        onChange={handleChangeLocation}
                        renderInput={(params) => <TextField {...params} label="Municipio sede" />}
                        renderGroup={(params) => (
                            <li key={params.key}>
                                <GroupHeader>{params.group}</GroupHeader>
                                <GroupItems>{params.children}</GroupItems>
                            </li>
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{ p: !isMobile ? 1 : 0, mt: 1 }}>
                    <TextField
                        fullWidth
                        label="Dirección física"
                        variant="outlined"
                        value={datosEmpresa.direccionFisica}
                        onChange={handleChange}
                        name="direccionFisica"
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{ p: !isMobile ? 1 : 0, mt: 1 }}>
                    <TextField
                        fullWidth
                        label="Código postal"
                        variant="outlined"
                        name="codigoPostal"
                        value={datosEmpresa.codigoPostal}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{ p: !isMobile ? 1 : 0, mt: 1 }}>
                    <Autocomplete
                        options={filteredOptions}
                        renderTags={(tags,getTagProps)=> null}
                        getOptionLabel={(option) =>`${option.id} - ${option.label}`}
                        value={actividadesEconomicas.filter(x => x.id === datosEmpresa.codigoCiiu).length > 0 ? actividadesEconomicas.filter(x => x.id === datosEmpresa.codigoCiiu)[0] : null}
                        onChange={handleChangeActivities}
                        renderInput={(params) => <TextField {...params} label="Actividad económica" />}
                    />
                </Grid>
                {datosEmpresa.tipoIdentificacion === "31" && (
                    <>
                        <Grid item xs={9} md={4} sx={{ p: !isMobile ? 1 : 0, mt: 1 }}>
                            <TextField
                                fullWidth
                                label="NIT"
                                variant="outlined"
                                value={datosEmpresa.identificacion}
                                onChange={handleChange}
                                name="identificacion"
                                type='number'
                            />
                        </Grid>
                        <Grid item xs={3} md={2} sx={{ p: !isMobile ? 1 : 0, mt: 1 }}>
                            <TextField
                                fullWidth
                                label="DV"
                                value={datosEmpresa.dv}
                                onChange={handleChange}
                                variant="outlined"
                                name="dv"
                                type='number'
                            />
                        </Grid>
                    </>
                )}
                {datosEmpresa.tipoIdentificacion !== "31" && (
                    <Grid item xs={12} md={6} sx={{ p: !isMobile ? 1 : 0, mt: 1 }}>
                        <TextField
                            fullWidth
                            label="Número de identificación"
                            variant="outlined"
                            value={datosEmpresa.identificacion}
                            onChange={handleChange}
                            name="identificacion"
                            type='number'
                        />
                    </Grid>
                )}
            </Grid>
        </>
    );
}

export default DatosEmpresaForm;
