import { Box, Checkbox, FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';
import { PropsCumplimientoLegal } from '../../shared/VinculacionesModels';
import React from 'react';

const CumplimientoLegalForm: React.FC<PropsCumplimientoLegal> = ({ cumplimientoLegal, setCumplimientoLegal }) => {
    const handleCheck = (event: any) => {
        const { name, checked } = event.target
        setCumplimientoLegal({
            ...cumplimientoLegal,
            [name]: checked
        })
    }

    return (
        <>
            <Typography variant='h6' sx={{ textAlign: 'left', mb: 3, mt: 3, fontWeight: '500' }}>
                Cumplimiento Legal
            </Typography>
            <FormGroup sx={{ justifyContent: 'center', textAlign: 'justify' }}>
                <FormControlLabel 
                    control={
                        <Switch 
                            checked={cumplimientoLegal.checkRegistro}
                            onClick={handleCheck}
                            value={cumplimientoLegal.checkRegistro}
                            name="checkRegistro"                        
                        />
                    } 
                    label="¿La empresa está registrada en el país donde opera?" 
                />
                <FormControlLabel 
                    control={
                        <Switch
                            checked={cumplimientoLegal.checkRegulaciones}
                            onClick={handleCheck}
                            value={cumplimientoLegal.checkRegulaciones}
                            name="checkRegulaciones" 
                        />
                    } 
                    label="¿Cumple con todas las regulaciones locales e internacionales?" 
                />
                <FormControlLabel 
                    control={
                        <Switch
                            checked={cumplimientoLegal.checkActividades} 
                            onChange={handleCheck}
                            value={cumplimientoLegal.checkActividades}
                            name="checkActividades" 
                        />
                    } 
                    label="¿Ha estado la empresa o alguno de sus directivos involucrado en actividades ilegales o financieras de dudosa procedencia?" 
                />
            </FormGroup>
            <Box sx={{ justifyContent: 'center', textAlign: 'justify', mt: '2rem' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            onClick={handleCheck}
                            value={cumplimientoLegal.checkTerminosCesion}
                            checked={cumplimientoLegal.checkTerminosCesion}
                            name="checkTerminosCesion"
                        />
                    }
                    label={<>Confirmo que he leído, entiendo y acepto la &nbsp;
                        <a href="https://cfinancia.cesionbnk.com/terminosycondiciones" target="_blank" rel="noopener noreferrer" style={{ color: '#2074d4' }}>
                            <u>Política de Seguridad, Privacidad y Tratamiento de Datos</u>
                        </a>.
                    </>}
                />
            </Box>
            <Box sx={{ justifyContent: 'center', textAlign: 'justify' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            onClick={handleCheck}
                            value={cumplimientoLegal.checkSagrilaft}
                            checked={cumplimientoLegal.checkSagrilaft}
                            name="checkSagrilaft"
                        />
                    }
                    label={<>Autorizo la &nbsp;
                        <a href="https://s3.amazonaws.com/public.cesione.com.co/a509a86a-31cf-49e9-a944-eb00d30415cd/AUTORIZACION_PARA_CONSULTA_Y_REPORTE_A_LAS_CENTRALES_DE_RIESGO.pdf" target="_blank" rel="noopener noreferrer" style={{ color: '#2074d4' }}>
                            <u>consulta en centrales de riesgo y en listas restrictivas (SAGRILAFT).</u>
                        </a>
                    </>}
                />
            </Box>
        </>
    )
}

export default CumplimientoLegalForm