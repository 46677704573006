import "./App.css"
import { Box, ThemeProvider, createMuiTheme } from "@mui/material"
import ResumenVinculacion from "./components/vinculacionCliente/vistaResumen"
import { TenantProvider } from "./shared/Context"
import { Footer, Header } from "./shared/Components"
import { MaintenancePage } from "./shared/Layout"
import { EnvContextProvider } from "./shared/Context/Environment/EnvironmentContext"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
const theme = createMuiTheme({
  typography: {
    fontFamily: `"Satoshi", sans-serif`,
  },
})

function App() {
  let maintenance = false
  if (maintenance) {
    return <MaintenancePage />
  }

  return (
    <EnvContextProvider>
      <TenantProvider>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="App">
              <Header />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  minHeight: "93vh",
                  background: "#fbfbfb",
                }}
              >
                {/* <VinculacionCliente /> */}
                <ResumenVinculacion />
              </Box>
              <Footer />
            </div>
          </LocalizationProvider>
        </ThemeProvider>
      </TenantProvider>
    </EnvContextProvider>
  )
}

export default App
